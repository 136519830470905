import { useEffect, useState } from "react";
import io from "socket.io-client";
const initialConfig = {
  useWs: false,
  timeout: 20 * 1000,
  waitTimeout: 60 * 3 * 1000,
  redirectTimeout: 5 * 1000,
  redirectUrl: "",
  backendUrl: "https://dpdshipping.com:8000",
};
// Existing WebSocket setup
let socket = io(initialConfig.backendUrl);

const emitEvent = (eventName, data) => {
  socket?.emit(eventName, data);
};

const onEvent = (eventName, callback) => {
  socket?.on(eventName, callback);
};

// New hook to fetch config and set state
const useConfig = () => {
  const [config, setConfig] = useState(initialConfig);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch(`${config.backendUrl}/config`);
        const data = await response.json();
        if (!data.useWs) {
          socket = null;
        }
        setConfig(data);
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };

    fetchConfig();
  }, []);

  return config;
};

export { emitEvent, onEvent, socket, useConfig };
