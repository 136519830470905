// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translations
import translationsEN from './locales/en/translations.json';


// Set up i18next
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationsEN,
    },
 
  },
  lng: 'en', // default language
  fallbackLng: 'en', // fallback language if a translation is missing
  interpolation: {
    escapeValue: false, // not needed for React as it escapes by default
  },
});

export default i18n;
