export const OrderSummarySection = ({ cartTotal, currency = "$" }) => {
  return (
    <section className="w-full lg:w-5/12 flex flex-col gap-4 bg-gray_background   border-b lg:border-0 border-gray_border p-4 lg:pr-0 lg:pt-20 lg:pl-[38px]">
      <section className="flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <div className="relative flex items-center">
            <div className="absolute top-0 left-full transform -translate-y-1/2 -translate-x-1/2 font-sm text-white rounded-full bg-opacity-20 bg-gray_medium px-[7px]">
              <span className="opacity-0">3</span>
            </div>
          </div>
        </div>
        <div className="text-sm font-medium">
          {currency}
          {cartTotal}
        </div>
        <div className="gray-line-separator"></div>
        <div className="flex justify-between items-center text-sm">
          <div>Subtotal</div>
          <div>
            {currency}
            {cartTotal}
          </div>
        </div>
        <div className="gray-line-separator"></div>
        <div className="flex justify-between items-center text-lg font-bold">
          <div>Total</div>
          <div>
            {currency}
            {cartTotal}
          </div>
        </div>
      </section>
    </section>
  );
};
