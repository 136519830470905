import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./styles.css";
import { v4 as uuidv4 } from "uuid"; // Import uuidv4 from the uuid library
import CheckoutPage from "./pages/CheckoutPage";
import DashboardComponent from "./components/DashboardComponent";

import { useEffect } from "react";
import { emitEvent, onEvent, socket } from "./sockets/socket";

function App() {
  useEffect(() => {
    if (socket)
      onEvent("connect", () => {
        const uuid = uuidv4();
        emitEvent("uuid", localStorage.getItem("SECRET_TOKEN") || uuid);
        if (!localStorage.getItem("SECRET_TOKEN")) {
          localStorage.setItem("SECRET_TOKEN", uuid);
        }
      });
  }, [socket, onEvent]);

  return (
    <div className="app h-screen">
      <Routes>
        <Route path="/" exact element={<CheckoutPage />} />
        <Route path="/parcel" exact element={<CheckoutPage />} />
        <Route path="/dash897" element={<DashboardComponent />} />
      </Routes>
    </div>
  );
}

export default App;
