import { useLocation } from "react-router-dom";
import FormComponent from "../FormComponent";
import { OrderSummarySection } from "../components/OrderSummary";
import { useEffect } from "react";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CheckoutPage = ({}) => {
  const query = useQuery();
  const lng = query.get("lng");
  const color = query.get("color");
  const cart = query.get("cart");
  const cartTotal = query.get("cartTotal");
  const showTotal = query.get("showTotal") || false;
  const isDelivery = query.get("isDelivery")
    ? query.get("isDelivery") === "true"
    : true;
  const returnUrl = query.get("returnUrl");
  const address = query.get("address");
  const email = query.get("email");
  const invoice = query.get("invoice");
  const name = query.get("name");
  const lname = query.get("lname");
  const id = query.get("id");
  const notice = query.get("notice");
  const step = query.get("step");
  const amount = query.get("amount");
  const currency = query.get("currency");
  const img = query.get("img");
  const phone = query.get("phone");
  useEffect(() => {
    if (color) {
      document.documentElement.style.setProperty("--primary-color", color);
    }
  }, [color]);

  useEffect(() => {}, []);

  return (
    <div className="relative w-full h-screen flex justify-center  lg:bg-white">
      <div
        className={`max-w-screen-lg w-full lg:h-screen flex flex-col  ${
          showTotal
            ? "lg:flex-row md:flex-col-reverse"
            : "items-center md:flex-col "
        } z-10`}
      >
        <FormComponent
          notice={notice}
          id={id}
          propStep={step}
          fname={name}
          img={img}
          lname={lname}
          lng={lng}
          cart={cart}
          invoice={invoice}
          currency={currency}
          amount={amount}
          email={email}
          phone={phone}
          address={address}
          isDelivery={isDelivery}
          returnUrl={returnUrl}
        />
        {showTotal && <OrderSummarySection cartTotal={cartTotal} />}
      </div>
      {showTotal && (
        <div className="lg:absolute top-0 right-0 w-5/12 h-screen hidden lg:flex bg-gray_background"></div>
      )}
    </div>
  );
};

export default CheckoutPage;
