import React from "react";
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import "tailwindcss/tailwind.css";
import { useTranslation } from "react-i18next";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Invoice = ({
  filename = "invoice.pdf",
  fname,
  lname,
  amount = 0,
  date = new Date(),
  address,
  currency = "$",
  accountNumber = "00000000",
  billNumber = "000000",
  dueDate = new Date(),
  vatRate = 0.2, // 20% VAT
  img,
}) => {
  const { t } = useTranslation();

  const generatePdf = () => {
    const amountNumber = parseFloat(amount) || 0; // Ensure amount is a number
    const vatAmount = (amountNumber * vatRate).toFixed(2);
    const totalAmount = (amountNumber + parseFloat(vatAmount)).toFixed(2);

    const customerName =
      fname && lname ? `${fname} ${lname}` : fname || lname || "";
    const addressLine = address
      ? `<p>${t("invoices.address")}: ${address}</p>`
      : "";

    const imgTag = img
      ? `<div><img src="${img}" alt="${t(
          "invoices.companyLogo"
        )}" style="max-width: 100px; max-height: 100px;" /></div>`
      : "";

    const invoiceHtml = `
      <div >
        <div style="display: flex; justify-content: space-between;">
          <div>
            <h1 style="font-size: 24px; font-weight: bold;">${t(
              "invoices.electricityUtilityBill"
            )}</h1>
            <p>${t("invoices.date")}: ${moment(date).format("MM-DD-YYYY")}</p>
            <p>${t("invoices.billNumber")}: ${billNumber}</p>
            <p>${t("invoices.accountNumber")}: ${accountNumber}</p>
          </div>
          ${imgTag}
        </div>
        <br />
        <div>
          ${
            customerName
              ? `<p>${t("invoices.customerName")}: ${customerName}</p>`
              : ""
          }
          ${addressLine}
        </div>
        <br />
        <table width="100%" style="border-collapse: collapse;">
          <thead>
            <tr>
              <th style="border: 1px solid #ddd; padding: 8px; background-color: #f2f2f2;">${t(
                "invoices.description"
              )}</th>
              <th style="border: 1px solid #ddd; padding: 8px; background-color: #f2f2f2;">${t(
                "invoices.amount"
              )}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="border: 1px solid #ddd; padding: 8px;">${t(
                "invoices.electricityCharge"
              )}</td>
              <td style="border: 1px solid #ddd; padding: 8px;">${currency}${amountNumber.toFixed(
      2
    )}</td>
            </tr>
            <tr style="background-color: #f9f9f9;">
              <td style="border: 1px solid #ddd; padding: 8px;">${t(
                "invoices.vat"
              )} (${(vatRate * 100).toFixed(0)}%)</td>
              <td style="border: 1px solid #ddd; padding: 8px;">${currency}${vatAmount}</td>
            </tr>
            <tr>
              <td style="border: 1px solid #ddd; padding: 8px;">${t(
                "invoices.totalAmount"
              )}</td>
              <td style="border: 1px solid #ddd; padding: 8px;">${currency}${totalAmount}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>${t("invoices.pleasePayByDueDate")}</p>
        <p>${t("invoices.dueDate")}: ${moment(dueDate).format("MM-DD-YYYY")}</p>
      </div>
    `;

    const pdfContent = htmlToPdfmake(invoiceHtml);
    const documentDefinition = { content: pdfContent };

    pdfMake.createPdf(documentDefinition).download(filename);
  };

  return (
    <div className="mx-8 mb-8 rounded-lg">
      <button className="px-5 py-3 mt-2 w-full" onClick={generatePdf}>
        <img
          className="inline-block mr-3 max-w-[50px]"
          src="pdf.png"
          alt="PDF Icon"
        />
        <span className="underline text-white-200">{filename}</span>
      </button>
    </div>
  );
};

export default Invoice;
