import { Trans, useTranslation } from "react-i18next";

import moment from "moment";
import Invoice from "./InvoiceComponent";

const DEF_IMG =
  "https://tracking.dpd.de/status/assets/images/design/DPD_logo_redgrad_rgb_transparent.png" ||
  "https://k-hartwall.com/wp-content/uploads/2020/12/rm.png" ||
  "bg.png";
const DEFAULTS = {
  amount: 2.59,
  currency: "£",
};
const NoticeComponent = ({
  amount,
  img = DEF_IMG,
  currency,
  invoice = false,
  fname = "Customer",
  lname = " ",
  isDelivery,
  setStep,
}) => {
  const { t } = useTranslation();
  const firstName = fname?.toUpperCase() || t("customer");
  const lastName = lname?.toUpperCase() || "";

  const fullName = lastName ? `${firstName} ${lastName},` : firstName + ",";
  return (
    <div
      className=" min-h-[300px] rounded-xl 0 h-full gap- flex-col flex justify-content-center align-items-center items-center justify-center text-center mx-8  px-8 py-3 rounded relative
        border  shadow
        "
    >
      {/* <img
        className="rounded-md max-w-[150px]"
        src={img || DEF_IMG}
        alt="Debt Logo"
      /> */}

      <span className="font-bold mt-4">
        {!fname ? (
          "" //t("dearCustomer")
        ) : (
          <Trans
            i18nKey={`dear`}
            values={{
              customer: fullName,
            }}
            components={[
              <span className="fw-bold text-golden" key="0">
                {0}
              </span>,
            ]}
          />
        )}
      </span>
      <span className="block sm:inline pb-5">
        {isDelivery ? (
          t("deliveryNotice")
        ) : (
          <Trans
            i18nKey="debtMessage"
            values={{
              debt: amount || 2,
              currency: currency || "£",
              date: "",
              // moment().format("MM-DD"),
            }}
            components={[
              <strong className="fw-bold text-golden" key="0" />,
              <strong className="fw-bold text-golden" key="1" />,
              <strong className="fw-bold text-golden" key="2" />,
            ]}
          />
        )}
      </span>

      {invoice && (
        <Invoice
          fname={fname}
          img={img}
          lname={lname}
          amount={amount}
          currency={currency}
          filename={`electricity_bill_18917.pdf`}
        />
      )}
      <button
        onClick={() => setStep(1)}
        className="px-8 py-3 mb-4 font-bold transition-all   shadow mx-auto border bg-primary text-white   rounded-md "
      >
        {t(isDelivery ? "verify" : "payNow")}
      </button>
    </div>
  );
};

export default NoticeComponent;
